import '../App.css';
import {
    Alert,
    Box,
    Button,
    Checkbox, Chip,
    Container, Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    InputLabel,
    LinearProgress,
    MenuItem,
    Select,
    Slider,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {useEffect, useRef, useState} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import {blue} from "@mui/material/colors";
import {styled} from '@mui/material/styles';
import {convertAiToFile, getFile} from "../Service/ConversionService";
import 'react-photo-view/dist/react-photo-view.css';
import JSZip from 'jszip';
import {saveAs} from 'file-saver';
import Popup from "./Popup";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import CheckIcon from '@mui/icons-material/Check';
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

const BASE_URI = process.env.REACT_APP_BASE_URI;
const { v4: uuidv4 } = require('uuid');
var droppedFiles = [];
var allFiles = [];
var sentFiles = [];
var uploadedFiles = [];

const Home = ()=>{

    const [theFile, setTheFile] = useState({type: null});
    const [loadFile, setLoadFile] = useState(false);
    const [activeImageFile, setActiveImageFile] = useState(null);
    const [isImage1Visible, setIsImage1Visible] = useState(true);
    const [scale, setScale] = useState(1);
    const ZOOM_SPEED = 0.05;
    const [isMoving, setIsMoving] = useState(false);
    const [startX, setStartX] = useState(0);
    const [startY, setStartY] = useState(0);
    const [offsetX, setOffsetX] = useState(0);
    const [offsetY, setOffsetY] = useState(0);
    const [initialOffsetX, setInitialOffsetX] = useState(0);
    const [initialOffsetY, setInitialOffsetY] = useState(0);
    const [originalFiles, setOriginalFiles] = useState([]);
    const [compressionImage, setCompressionImage] = useState(80);

    const [optionsFile, setOptionsFile] = useState([
        {value: "png", label: "PNG"},
        {value: "pdf", label: "PDF"},
        {value: "jpeg", label: "JPG"},
        {value: "webp", label: "WEBP"},
        {value: "tiff", label: "TIFF"},
        {value: "bmp", label: "BMP"},
        {value: "gif", label: "GIF"},
        {value: "svg", label: "SVG"}
    ]);
    const [outputFile, setOutputFile] = useState("");
    const [openCompareDialog, setOpenCompareDialog] = useState(false);
    const [convertedFiles, setConvertedFiles] = useState([]);
    const [downloadFiles, setDownloadFiles] = useState([]);
    const [configuredWidth, setConfiguredWidth] = useState("");
    const [configuredHeight, setConfiguredHeight] = useState("");
    const imagesInputRef = useRef(null);
    const [activeImage, setActiveImage] = useState(null);
    const [activeIndex, setActiveIndex] = useState(null);
    const [downoadAll, setDownloadAll] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [openVisualiseDialog, setOpenVisualiseDialog] = useState(false);
    const [hasZoomed, setHasZoomed] = useState(false);

    const [activeFileFromAi, setActiveFileFromAi] = useState(false);
    const [activeFileAi, setActiveFileAi] = useState(false);
    const bottomRef = useRef(null);
    const [uploadProgress, setUploadProgress] = useState([]);
    const [allImagesLoaded, setAllImagesLoaded] = useState(false);
    const [disabledConvertedFiles, setDisabledConvertedFiles] = useState(false);

    useEffect(() => {

        const images = document.querySelectorAll('img');
        const imageCount = images.length;
        let loadedCount = 0;

        const handleImageLoad = () => {
            loadedCount++;
            if (loadedCount === imageCount) {
                setAllImagesLoaded(true);
            }
        };

        images.forEach(image => {
            if (image.complete) {
                handleImageLoad();
            } else {
                image.addEventListener('load', handleImageLoad);
            }
        });

        return () => {
            images.forEach(image => {
                image.removeEventListener('load', handleImageLoad);
            });
        };
    }, []);

    useEffect(() => {
        if (allImagesLoaded) {
            scrollToBottom();
        }
    }, [allImagesLoaded]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [activeFileAi]);

    const scrollToBottom = () => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth'
        });
    };

    const handleKeyPress = (event) => {
        if (activeFileAi){
            if (event.key === 'b' || event.key === 'B') {
                // Appeler votre fonction ici
                toggleImage();
            }
            if (event.key === 'i' || event.key === 'I') {
                resetTransform();
            }
        }
    };

    const handleChangeSelectedOutputFile = async (event)=> {
        setConvertedFiles([]);
        setActiveFileAi(false);
        setActiveFileFromAi(false);
        setOutputFile(event.target.value);
    }

    const handleChangeCompressionImage = async (event)=> {
        setCompressionImage(event.target.value);
    }

    const handleMouseDown = (e) => {
        e.preventDefault();
        setIsMoving(true);
        setStartX(e.clientX);
        setStartY(e.clientY);
        setInitialOffsetX(offsetX);
        setInitialOffsetY(offsetY);
    };

    const handleDeleteImage = (e, file, index)=>{

        const userConfirmed = window.confirm('Voulez-vous vraiment supprimer ce historique?');
        if (userConfirmed === true){
            var updatedFiles = originalFiles.filter((item) => {
                return item.theFile.name !== file.name;
            });

            var updatedSentFiles = sentFiles.filter((item)=> {
                return item.originalFileName !== file.name;
            });
            setOriginalFiles(updatedFiles);
            sentFiles = updatedSentFiles;
        }
    }

    const handleMouseMove = (e) => {
        if (isMoving) {
            const newOffsetX = initialOffsetX + (e.clientX - startX);
            const newOffsetY = initialOffsetY + (e.clientY - startY);

            setOffsetX(newOffsetX);
            setOffsetY(newOffsetY);
        }
    };

    const handleMouseUp = () => {
        setIsMoving(false);
    };

    const resetTransform = () => {
        setScale(1);
        setOffsetX(0);
        setOffsetY(0);
    };

    const handleWheel = (e) => {

        if (e.cancelable) {
            e.preventDefault();
        }

        setScale((prevScale) => {
            let newScale = prevScale + (e.deltaY > 0 ? -ZOOM_SPEED : ZOOM_SPEED);
            console.log("Scale: ", newScale);
            newScale = Math.max(newScale, 0.1);
            console.log("Scale 2 : ", newScale);
            return newScale;
        });

        setHasZoomed(true);
    };

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const handleUploadImage2 = async (event, dropped = false) => {
        event.preventDefault();
        // Gérer le changement de fichier ici, si nécessaire
        var imageDatas = [];
        setConvertedFiles([]);

        if (dropped){
            //##Here will be the task process
            allFiles = event.dataTransfer.files;
        }else{
            var file = event.target.files[0];
            droppedFiles = [file];
            var updatedFiles = [file.name];
            setTheFile(file);
            allFiles = event.target.files;
        }

        setDisabledConvertedFiles(true);
        setActiveFileAi(false);
        setActiveFileFromAi(false);
        updatedFiles = [];
        uploadedFiles = [];

        // Array.from pour convertir la liste des fichiers en tableau
        Array.from(allFiles).forEach((file, i) => {
            var item = {theFile: file, fileName: "", progress: 0 };
            uploadedFiles = [...uploadedFiles, item];
        });
        var uFiles = [...(originalFiles), ...uploadedFiles];
        setOriginalFiles(uFiles);
        console.log("Selected files: ", uFiles);

        // Reset progress state
        setUploadProgress(Array(uploadedFiles.length).fill(0));

        for (let i = 0; i < uploadedFiles.length; i++) {
            const file = uploadedFiles[i];
            // Create form data
            const formData = new FormData();
            const UUID = formatUUIDWithPrefix();
            formData.append("theFile", file["theFile"]);
            formData.append("uuid", UUID);

            // Send request to upload file
            try {
                const response = await axios.post(`${BASE_URI}/conversion/upload`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: (progressEvent) => {
                        // Update upload progress
                        const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);

                        /*setUploadProgress(prevProgress => {
                            const newProgress = [...prevProgress];
                            var index = array.findIndex(function(obj) {
                                return obj.id === objectIdToUpdate;
                            });
                            if (index !== -1) {
                                array[index].name = newName;
                            }
                            newProgress[i] = progress;
                            return newProgress;
                        });*/
                        setOriginalFiles(prevFiles => {
                            const newProgress = [...prevFiles];
                            var foundIndex = prevFiles.findIndex(function(obj) {
                                return obj.theFile.name === file.theFile.name;
                            });
                            if (foundIndex !== -1) {
                                newProgress[foundIndex].progress = progress;
                            }
                            return newProgress;
                        });
                    }
                });
                var result = response.data;
                var infosFile = {fileName: result.fileName, urlFile: result.urlFile, originalFileName: file.theFile.name};
                sentFiles = [...sentFiles, infosFile];
                if (i === uploadedFiles.length){
                    setDisabledConvertedFiles(false);
                }

            } catch (error) {
                console.error(`Error uploading file ${file.name}:`, error);
            }
        }

    };

    const displaySelectedImage = (currentFile, index)=> {

        var file = currentFile.theFile;
        var fileName = file.name, res, type = file.type,
            typeSplited = type.split("/"),
            typeFirstPart = typeSplited[0];
        var fileNameSplited = fileName.split(".");
        var partName = fileNameSplited[0],
            extension = fileNameSplited[1],
            extensionLower = extension.toLowerCase();

        if (typeFirstPart !== "image"){
            res = <Grid item sm={12} md={4} key={index} className="image-item" style={{position:"relative"}}>
                    <FilePresentIcon key={index} sx={{ fontSize: 40 }} />
                    <p className="image-name">
                        <span style={{fontSize: "0.75rem"}}>{file.name}</span>{" "}
                    </p>
                    {deleteBtn(file, index)}

                    <Box sx={{ width: '100%' }}>
                        <LinearProgressWithLabel value={currentFile.progress} />
                    </Box>
                </Grid>
        }else {
            res = <Grid item sm={12} md={4} key={index}>
                    <div className="img-c">
                        <img src={URL.createObjectURL(file)} alt={`Image ${index}`}
                             style={{
                                 width: configuredWidth !== "" ? configuredWidth : '100%',
                                 height: 'auto',
                                 display: 'block'}} className="image-preview" />

                        <p className="image-name">
                            <span style={{fontSize: "0.75rem"}}>{file.name}</span>{" "}

                            {currentFile.progress === 100 && (
                                <span style={{color:"rgb(49, 162, 76)", fontFamily:"bolt!important"}}>ok <CheckIcon sx={{ fontSize: 15 }} /></span>
                            )}

                        </p>

                        {currentFile.progress < 100 && (
                            <Box sx={{ width: '100%' }}>
                                <LinearProgressWithLabel value={currentFile.progress} />
                            </Box>
                        )}
                        {deleteBtn(file, index)}
                    </div>
                </Grid>
        }
        return res;

    }

    const handleUploadImage = async ()=>{

        if (activeFileAi || activeFileFromAi){
            // Créer un élément <a> pour déclencher le téléchargement
            const link = document.createElement('a');

            if (activeFileAi){
                var fileName = theFile.name;
                var fileSplit = fileName.split(".");
                var partName = fileSplit[0];

                link.href = activeImageFile;
                link.download = partName + ".svg";

                document.body.appendChild(link);
                link.click();
                if (activeFileAi){
                    window.URL.revokeObjectURL(activeImageFile);
                }
            }

            if (activeFileFromAi){
                var currentDate = new Date();
                // Get the full date and time components separately
                const year = currentDate.getFullYear();
                const month = currentDate.getMonth() + 1; // Months are zero-indexed, so add 1
                const day = currentDate.getDate();
                const hours = currentDate.getHours();
                const minutes = currentDate.getMinutes();
                const seconds = currentDate.getSeconds();

                // Format the date and time components as desired
                const formattedDateTime = `FILES_CONVERTED_${year}_${month}_${day}_${hours}${minutes}${seconds}`;
                const zip = new JSZip();
                const folder = zip.folder(formattedDateTime);
                var hasElem = false;

                await Promise.all(downloadFiles.map(async (fileInfos, index) => {

                    hasElem = true;
                    const res = await getFile({urlFile: fileInfos.urlFile, fileName: fileInfos.fileName});
                    const blob = await res.data;
                    folder.file(`FILE_${index+1}.${fileInfos.extension}`, blob);

                }));

                if (hasElem){
                    const zipBlob = await zip.generateAsync({ type: "blob" });
                    saveAs(zipBlob, formattedDateTime + ".zip");
                }
            }

        }else {

            const formData = { domain: 'same', outputFormat: outputFile, compression: compressionImage };
            /*if (theFile.type === 'image/jpeg' ||       // JPEG image
                theFile.type === 'image/png'           // PNG image
            ) {*/

            //}else {
            /*if (theFile.type === "application/postscript" || theFile.type === "image/svg+xml"
            ) {*/
            console.log("Files: ", sentFiles);
            var counter = 0,
                dataFiles = [];
            for (let i = 0; i < sentFiles.length; i++) {
                const infosFileSent = sentFiles[i];
                console.log("infos: ", infosFileSent);
                if (infosFileSent !== undefined){
                    var dataFile = {
                        configuredWidth: configuredWidth,
                        configuredHeight: configuredHeight,
                        urlFile: infosFileSent["urlFile"],
                        localFileName: infosFileSent["fileName"]
                    };
                    dataFiles = [...dataFiles, dataFile];

                    /*formData.append(`${fileKey}configuredWidth`, configuredWidth);
                    formData.append(`${fileKey}configuredHeight`, configuredHeight);
                    formData.append(`${fileKey}urlFile`, infosFileSent["urlFile"]);
                    formData.append(`${fileKey}localFileName`, infosFileSent["fileName"]);
                    formData.append(`${fileKey}uuid`, UUID);
                    counter++;*/
                }
            }

            formData["files"] = dataFiles;
            /*formData.append('TOTAL_FILES', counter);
            formData.append('outputFormat', outputFile);
            formData.append('compression', compressionImage);*/
            console.log("DATAS to send: ", formData);

            var res;
            if (!outputFile){
                setHasError(true);
            }else {

                setLoadFile(true);
                setHasError(false);

                if (outputFile === "svg"){

                    res = await convertAiToFile(formData);
                    setLoadFile(false);
                    if (res.response === "success"){
                        setDownloadFiles(res.files);
                        setConvertedFiles(res.files);
                        setActiveImageFile(res.urlVectoriseFile);
                        setActiveFileAi(true);
                        setActiveFileFromAi(true);
                    }

                }else {

                    res = await convertAiToFile(formData);
                    if (res.response === "success"){
                        setDownloadFiles(res.files);
                        setConvertedFiles(res.files);
                        setActiveFileAi(false);
                        // activeFileAi = false;
                        setActiveFileFromAi(true);
                        // activeFileFromAi = true;

                    }
                }
                setLoadFile(false);
                setOriginalFiles([]);
                sentFiles = [];

                /*}else {
                    alert("Type du fichier glissé incorrect.");
                    console.error("ERROR FILETYPE: ", theFile.type);
                }*/

                //}
            }
        }
    }

    const handleSaveAs = async (e, file, index)=> {

        // Créer un objet URL à partir du Blob
        const res = await getFile({ fileName: file.fileName});
        const blob = await res.data;

        const url = URL.createObjectURL(blob);
        // Créer un élément de lien
        const link = document.createElement('a');
        // Définir l'URL du lien sur l'URL du Blob
        link.href = url;
        // Définir le nom de fichier lors du téléchargement
        link.download = file.fileName;
        // Ajouter le lien au DOM
        document.body.appendChild(link);
        // Simuler un clic sur le lien pour déclencher le téléchargement
        link.click();
        // Nettoyer
        URL.revokeObjectURL(url);
        document.body.removeChild(link);

    }

    const toggleImage = () => {
        setIsImage1Visible(prevState => !prevState);
    };

    const formatUUIDWithPrefix = (prefix = null) => {
        const uuid = uuidv4(); // Génère un UUID v4 unique
        if (prefix){
            return prefix + uuid;
        }else {
            return uuid;
        }
    }

    const handleOpenDialogCompare = (e, file, index) => {
        setActiveIndex(index);
        setActiveImage(file);
        setOpenCompareDialog(true);
    };

    const handleOpenDialogVisualise = (e, file) => {
        setActiveImage(file);
        setOpenVisualiseDialog(true);
    };

    const handleCloseDialogCompare = () => {
        setOpenCompareDialog(false);
        resetTransform();
    };

    const handleCloseDialogVisualise = () => {
        setOpenVisualiseDialog(false);
    };

    const dialogContentCompare = <div style={{width:"680px"}}>
        <Grid container columns={12}>
            <Grid item md={12}>
                {activeImage && (
                    <div style={{textAlign:"center"}}>
                        <div className="zoomable-image-container" style= {{
                            position:"relative",
                            width: "100",
                            height: "500px",
                            backgroundImage: "url('/media/images/damier_front.jpg')",
                            backgroundSize: "cover",
                            borderRadius: "5px",
                            overflow: "hidden",
                        }}
                        >
                            <Typography variant="subtitle2" gutterBottom className={"absolutely-positioned"}>
                                {isImage1Visible ? "Image vectorisé" : "Image originale"}
                            </Typography>
                            <div
                                style={{
                                    margin:"auto",
                                    position: 'relative',
                                    width: '800px',
                                    height: '800px',
                                }}
                                onMouseMove={handleMouseMove}
                                onMouseUp={handleMouseUp}
                                onWheel={handleWheel}
                            >
                                <img
                                    id="zoomable-img"
                                    src={isImage1Visible ? activeImage.urlFile : URL.createObjectURL(allFiles[activeIndex])}
                                    alt="Your image"
                                    style={{
                                        position: 'absolute',
                                        left: `${offsetX}px`,
                                        top: `${offsetY}px`,
                                        width: `${scale * 100}%`,
                                        transform: hasZoomed ? `translate(-50%, -50%)` : 'none',
                                        height: 'auto',
                                        cursor: isMoving ? 'grabbing' : 'grab',
                                    }}
                                    onMouseDown={handleMouseDown}
                                    draggable="false"
                                />
                            </div>


                        </div>
                    </div>
                )}
            </Grid>
        </Grid>
    </div>;

    const dialogContentVisualise = <div style={{minWidth:"300px", maxWidth:"580px"}}>
        <Grid container columns={12}>
            <Grid item md={12}>

                {activeImage && (
                    <div style={{textAlign:"center"}}>
                        <div className="zoomable-image-container" style= {{
                            position:"relative",
                            width: "100",
                            height: "500px",
                            borderRadius: "5px",
                            overflow: "hidden",
                        }}
                        >
                            <div
                                style={{
                                    margin:"auto",
                                    position: 'relative',
                                }}
                            >
                                <img
                                    style={{maxWidth: '100%',
                                        height: 'auto',
                                        display: 'block'}}
                                    src={activeImage.urlFile}
                                    alt="Your image"
                                    draggable="false"
                                />
                            </div>

                        </div>
                    </div>
                )}

            </Grid>
        </Grid>
    </div>;

    const dialogActionCompare = <>
        <Button onClick={handleCloseDialogCompare}>Fermer</Button>
    </>;

    const dialogActionVisualise = <>
        <Button onClick={handleCloseDialogVisualise}>Fermer</Button>
    </>;

    const handleDownload = (e, file, index)=>{

        var updatedDownloadFiles = downloadFiles;
        var checked = e.target.checked;

        if (checked){
            let index = convertedFiles.indexOf(item => item.fileName === file.fileName);
            if (index === -1){
                updatedDownloadFiles = [...updatedDownloadFiles, file];
            }
        }else {
            updatedDownloadFiles = convertedFiles.filter(item => item.fileName !== file.fileName);
        }
        setDownloadFiles(updatedDownloadFiles);

    }

    const handleDownloadAll = (e)=> {
        var checked = e.target.checked;
        if (checked){
            setDownloadAll(true);
            setDownloadFiles(convertedFiles);
        }else {
            setDownloadAll(false);
            setDownloadFiles([]);
        }
    }

    const handleShowFile = (file)=> {
        var res;
        if (outputFile !== "svg"){
            if (outputFile === "pdf"){
                res = <PictureAsPdfIcon sx={{ fontSize: 40 }} />
            }else {
                res = <img
                    style={{
                        width: configuredWidth !== "" ? configuredWidth : '100%',
                        maxWidth:'100%',
                        height: 'auto',
                        display: 'block'}}
                    alt="Visuel dtf"
                    className="img rounded-4"
                    src={file.urlFile}
                    onClick={(e)=> {
                        if (outputFile !== "svg") {
                            handleOpenDialogVisualise(e, file);
                        }
                    }}
                />
            }
        }else {
            res = <img
                style={{
                    width: configuredWidth !== "" ? configuredWidth : '100%',
                    maxWidth:'100%',
                    height: 'auto',
                    display: 'block'
                }}
                alt="Visuel dtf"
                className="img rounded-4"
                src={file.urlFile}
            />
        }
        return res;
    }

    const deleteBtn = (file, index)=> {
        return <div className="del-top-right">
                    <Button
                        style={{minWidth:"40px"}}
                        size={"small"}
                        variant={"contained"}
                        color={"error"} onClick={(e)=> handleDeleteImage(e, file, index)}>
                        X
                    </Button>
                </div>
    };

    const LinearProgressWithLabel = (props) => {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress variant="determinate" {...props} />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">{`${Math.round(
                        props.value,
                    )}%`}</Typography>
                </Box>
            </Box>
        );
    }

    var textUpload = "Ajouter des logos",
        textUploadMore = "Ajouter plus";

    const uploadForm = <div className="custom-upload" id="customUpload"
                            style= {{display:"flex", justifyContent:"center", alignItems:"center"}}
                            onDragOver={(e) => e.preventDefault()}
                            onDrop= {(e) => handleUploadImage2(e, true)}
    >
        <label htmlFor="uploadInput">
            <Button
                style={{color:"#fff"}}
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<AddIcon />}
            >

                {originalFiles.length === 0 ? textUpload : textUploadMore }

                <VisuallyHiddenInput
                    ref={imagesInputRef}
                    type="file"
                    multiple
                    onChange={(e) => handleUploadImage2(e)} />
            </Button>
        </label>
    </div>;

    const btnDownOrConvert = <Box sx={{ position: 'relative' }}>
            <Button
                style={{width: "200px"}}
                size={"large"}
                variant="contained"
                disabled={loadFile}
                onClick={(e)=> handleUploadImage(e)}
            >
                {activeFileAi || activeFileFromAi ? "Télécharger" : "Convertir"}
            </Button>
            {loadFile && (
                <CircularProgress
                    size={24}
                    sx={{
                        color: blue[500],
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                    }}
                />
            )}
    </Box>;

    return (
       <Container >

           <Typography variant="h4" gutterBottom style={{marginTop:"30px", marginBottom:"30px"}}>
               CONVERSION IMAGES
           </Typography>
           <Alert severity="info">
               Convertissez des images dans tous les formats.
               Des JPEG , PNG en SVG.
               Des AI , EPS, SVG en png / jpeg / tiff / gif / pdf / webp
           </Alert>
           <Grid container style={{marginTop:"25px"}}>
               {originalFiles.length === 0 && <>
                   <Grid item xs={12} sm={12}>
                       {uploadForm}
                   </Grid>
               </>}
               <Grid item sm={12} md={6}>
                   <Stack direction={"column"}>
                       {convertedFiles.length === 0 && (
                           <div style={{textAlign:"start",marginBottom:"10px"}}>
                               { originalFiles.length > 0 && (
                                   <Typography variant="h6" gutterBottom>
                                       Fichier séléctionné:
                                   </Typography>
                               )
                               }

                               <Grid container direction="row" spacing={2}>
                                   { originalFiles.map((item, index) => (
                                       displaySelectedImage(item, index)
                                   ))}

                                   { originalFiles.length > 0 && (
                                       <Grid item sm={4}>
                                           {uploadForm}
                                       </Grid>
                                   )
                                   }
                               </Grid>
                           </div>
                       )}
                       <div style={{textAlign:"center"}}>
                           {convertedFiles.length > 0 && (
                               <Typography variant={"h6"} gutterBottom>
                                   Fichier converti
                               </Typography>
                           )}

                           <Grid container columns={12} justifyContent={"center"} >
                               {convertedFiles.length > 0 && (
                                   <Grid item sm={12}>
                                       <Stack
                                           direction="row"
                                           divider={<Divider orientation="vertical" flexItem />}
                                           spacing={2}
                                           alignItems={"center"}
                                           justifyContent={"center"}
                                           style={{marginBottom:"20px"}}
                                       >
                                           <Typography variant="subtitle2" gutterBottom>
                                               Largeur {configuredWidth !== "" ? <Chip label={configuredWidth + " pixel"} color="success" variant="outlined" /> : null}
                                           </Typography>

                                           <Typography variant="subtitle2" gutterBottom>
                                               Hauteur {configuredHeight !== "" ? <Chip label={configuredHeight +" pixel"} color="success" variant="outlined" /> : null}
                                           </Typography>

                                           <Typography variant="subtitle2" gutterBottom>
                                               Fichier de sortie {outputFile ? <Chip label={outputFile} color="success" variant="outlined" /> : null}
                                           </Typography>
                                       </Stack>

                                       {btnDownOrConvert}

                                   </Grid>
                               )}
                           </Grid>

                           <Grid container className="h-100" spacing={2} style={{overflow: "auto"}} >
                               {convertedFiles.map((file, index) => {
                                   return <Grid item sm={12}  md={3} key={index} style={{marginBottom:"5px", cursor:"pointer"}}>
                                       <Grid container direction={"row"} alignItems={"center"} spacing={1} className="bg-light round-4 px-3 py-2 mb-2 rounded-3" style={{backgroundColor:"light", padding:"0.5rem 0.5rem"}}>
                                           <Grid item sm={12} className="logo rounded-4 p-1 border border-3">
                                               <div className="img-c" style={{padding:"5px",
                                                   backgroundImage: "url('/media/images/damier_front.jpg')",
                                                   backgroundSize: "cover"}}>
                                                   { handleShowFile(file) }
                                               </div>
                                           </Grid>

                                           { outputFile && outputFile === "svg" && (
                                               <Grid item sm={12} sx={{textAlign:"start"}}>
                                                   <Button size="small" variant={"outlined"} onClick={(e)=> handleOpenDialogCompare(e, file, index)}>Comparer</Button>
                                               </Grid>
                                           )
                                           }

                                           <Grid item sm={12} sx={{textAlign:"start"}}>
                                               <Button size="small" variant={"contained"} onClick={(e)=> handleSaveAs(e, file, index)}>Enrégistrer</Button>
                                           </Grid>

                                           <Grid item sm={12} sx={{textAlign:"start"}}>
                                               <FormGroup>
                                                   <FormControlLabel control={<Checkbox defaultChecked onChange={(e)=> handleDownload(e, file, index)} />} label="Zipper" />
                                               </FormGroup>
                                           </Grid>

                                       </Grid>
                                   </Grid>
                               })
                               }
                           </Grid>
                       </div>
                   </Stack>
               </Grid>
               <Grid item sm={12} md={6}>
                    <Grid container spacing={2} alignItems={"center"} justifyContent={"center"} columns={12}>
                        {originalFiles.length > 0 && <>
                                <Grid item sm={12} md={"auto"}>
                                    <Typography variant="h6" gutterBottom>
                                        Taille image souhaitée
                                    </Typography>
                                    <Grid container spacing={1} columns={12} justifyContent={"center"}>
                                        <Grid item sm={12} md={"auto"} >
                                            <TextField
                                                id="l-image"
                                                label="Largeur max"
                                                helperText="pixel"
                                                value={configuredWidth}
                                                name={"originalWidth"}
                                                onChange={(e)=>{
                                                    setConfiguredWidth(e.target.value);
                                                }}
                                                style={{backgroundColor:"#fff", width: "100px"}}
                                                inputProps={{ style: { textAlign: 'center', borderRadius:"10px"}}}
                                            />
                                        </Grid>

                                        <Grid item sm={12} md={"auto"} >
                                            <TextField id="h-image"
                                                       label="Hauteur max"
                                                       helperText="pixel"
                                                       value={configuredHeight}
                                                       name={"originalHeight"}
                                                       onChange={(e)=>{
                                                           setConfiguredHeight(e.target.value);
                                                       }}
                                                       style={{backgroundColor:"#fff", width: "100px"}}
                                                       inputProps={{ style: { textAlign: 'center' }}}
                                            />
                                        </Grid>

                                        <Grid item sm={12}>
                                            <FormControl style={{width:"200px"}} error={hasError}>
                                                <InputLabel id="demo-simple-select-label">
                                                    Fichier de sortie
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    name="outputFile"
                                                    label={"Fichier de sortie"}
                                                    value={outputFile}
                                                    onChange={handleChangeSelectedOutputFile}
                                                >
                                                    {optionsFile.map(
                                                        (item, index) => (
                                                            <MenuItem
                                                                key={index}
                                                                value={item["value"]}
                                                            >
                                                                {item.label}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                                <FormHelperText>{ hasError ? "Fichier de sortie ne peut être null" : "" }</FormHelperText>
                                            </FormControl>
                                            {outputFile === "jpeg" && <>
                                                <Typography variant="caption" display={"block"} gutterBottom>
                                                    Taux de compression
                                                </Typography>
                                                <Box>
                                                    <Slider onChange={(event)=> handleChangeCompressionImage(event)} defaultValue={compressionImage} aria-label="Default" valueLabelDisplay="auto" />
                                                </Box>
                                            </>
                                            }

                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        }
                        {originalFiles.length > 0 && <>
                                <Grid item sm={12}>
                                    <Grid item sm={12}>
                                        {btnDownOrConvert}
                                    </Grid>
                                </Grid>
                            </>
                        }
                    </Grid>
                </Grid>
           </Grid>

           <div ref={bottomRef} />

           <Popup open={openCompareDialog}
                  children={dialogContentCompare}
                  dialogActions={dialogActionCompare}
                  title={"Comparaison des fichier original et fichier converti"}
                  onDialogClose={handleCloseDialogCompare} />

           <Popup open={openVisualiseDialog}
                  children={dialogContentVisualise}
                  dialogActions={dialogActionVisualise}
                  title={"VISUALISATION"}
                  onDialogClose={handleCloseDialogVisualise} />

       </Container>
    );
}
export default Home;
